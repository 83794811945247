import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import MobileMenu from "../components/MobileMenu";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import BlockTitle from "../components/BlockTitle";
import parse from "html-react-parser";
import Footer from "../components/Footer";

const Cooperate = ({ data, pageContext }) => {
  return (
    <Layout pageTitle="Együttműködés | MyFoodProtocol">
      <Header inner={true} btnClass="main-nav__btn-two" />
      <MobileMenu inner={true} />
      <PageHeader
        pageHeading={pageContext.title ? pageContext.title : "Együttműködés"}
        breadcrumb={[{ url: "/egyuttmukodes", title: "Együttműködés" }]}
      />

      <div className="container">
        <div className="my-5">
          <BlockTitle
            textAlign="left"
            paraText="Együttműködés"
            titleText={data.strapiCooperate.Title}
          />
          <div className="lead">
            {parse(data.strapiCooperate.Lead.data.Lead)}
          </div>
          <div>{parse(data.strapiCooperate.Content.data.Content)}</div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default Cooperate;

export const query = graphql`
  query CooperateQuery {
    strapiCooperate {
      Title
      Lead {
        data {
          Lead
        }
      }
      Content {
        data {
          Content
        }
      }
    }
  }
`;
