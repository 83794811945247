import React, { useState } from "react";
import axios from "axios";

import FooterLogo from "../assets/images/logo-1-1.png";
import EUblokk from "../assets/images/EUblokk.png";
import tiktok from "../assets/images/tik-tok.png";
import AppleDownload from "../assets/images/download-apple.png";
import GoogleDownload from "../assets/images/download-google-play.png";
import hiventuresLogo from "../assets/images/hiventures-logo.png";
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha";

const Footer = () => {
  const [visible, setVisible] = useState(true);
  const [errors, setErrors] = useState([]);
  const [msg, setMsg] = useState("");
  const [type, setType] = useState("felhasználó");
  const [submitted, setSubmitted] = useState(false);

  const submit = (token) => {
    // console.log("submit token", token);
    // e.preventDefault();
    const form = document.getElementById("newsletter-form");
    const formData = {
      name: form.elements.name.value,
      email: form.elements.email.value,
      type: form.elements.type.value,
      terms: form.elements.terms.checked,
      token: token,
    };
    const err = [];
    if (!formData.terms) {
      err.push("Az adatvédelmi nyilatkozat elfogadása kötelező!");
    }
    if (!formData.email) {
      err.push("Az e-mail cím mező kötelező!");
    } else if (
      !formData.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      )
    ) {
      err.push("Nem megfelelő e-mail cím!");
    }
    console.log("err", err);
    if (err.length) {
      setErrors(err);
    } else {
      setErrors([]);
      axios
        .post(`${process.env.API_URL}/newsletter/subscribe`, formData)
        .then((response) => {
          if (response.data === true) {
            setVisible(false);
            setMsg("Köszönjük a feliratkozását!");
          } else {
            setErrors(["Hiba történt az adatok küldése során!"]);
          }
        });
    }
    return false;
  };

  return (
    <div>
      <ReCaptcha
        action="newsletter"
        badge="inline"
        size="invisible"
        siteKey="6LfEOxEkAAAAANhe4GcEWOM4-yA55Op3b8L_wYWt"
        onVerify={(token) => submit(token)}
        submitted={submitted}
      />
      <section className="mailchimp-one">
        <div className="container wow fadeInUp">
          <div className="inner-container">
            <div className="text-center d-flex d-md-none">
              <div className="mailchimp-one__icon">
                <i className="apton-icon-mail"></i>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <div className="mailchimp-one__icon d-none d-md-flex">
                <i className="apton-icon-mail"></i>
              </div>
              {visible && (
                <form id="newsletter-form" className="mailchimp-one__form">
                  <div className="row">
                    <div className="col-md-6">
                      <input type="text" placeholder="Név" name="name" />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        placeholder="E-mail cím"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="type1"
                          name="type"
                          value="felhasználó"
                          checked={type === "felhasználó"}
                          onChange={(e) => {
                            setType(e.target.value);
                          }}
                        />
                        <label className="form-check-label" htmlFor="type1">
                          Páciens vagyok
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="type2"
                          name="type"
                          value="szakember"
                          checked={type === "szakember"}
                          onChange={(e) => {
                            setType(e.target.value);
                          }}
                        />
                        <label className="form-check-label" htmlFor="type2">
                          Terapeuta vagyok
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="type3"
                          name="type"
                          value="szolgáltató"
                          checked={type === "szolgáltató"}
                          onChange={(e) => {
                            setType(e.target.value);
                          }}
                        />
                        <label className="form-check-label" htmlFor="type3">
                          Egyéb szolgáltató vagyok
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-8">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="terms"
                          id="terms"
                          value={true}
                        />
                        <label className="form-check-label" htmlFor="terms">
                          Hozzájárulok az adataim kezeléséhez és elfogadom az
                          adatvédelmi nyilatkozatot.
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <button
                        className="thm-btn mailchimp-one__btn mt-3 mt-lg-0"
                        onClick={(e) => {
                          e.preventDefault();
                          setSubmitted(true);
                          return false;
                        }}
                      >
                        <span>Feliratkozom</span>
                      </button>
                    </div>
                  </div>
                  {errors.length > 0 && (
                    <div className="alert alert-danger w-100 mt-3" role="alert">
                      {errors.map((error, index) => (
                        <p className="mb-0" key={index}>
                          {error}
                        </p>
                      ))}
                    </div>
                  )}
                </form>
              )}

              {msg && (
                <div className="alert alert-success w-100 mt-3" role="alert">
                  {msg}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <footer className="site-footer">
        <div className="site-footer__upper">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="footer-widget footer-widget__about">
                  <a href="index.html">
                    <img src={FooterLogo} width="129" alt="awesome post" />
                  </a>
                  <div className="footer-widget mt-4">
                    <h3 className="footer-widget__title mb-4">Kövess minket</h3>
                    <div className="footer-widget__social mb-3">
                      <a
                        href="https://www.facebook.com/myfoodprotocol"
                        target="_blank"
                      >
                        <i className="fab fa-facebook-square"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/myfoodprotocol"
                        target="_blank"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCI7FYoyoBmw5uXjoUckgMHw"
                        target="_blank"
                      >
                        <i className="fab fa-youtube"></i>
                      </a>
                      <a href="#none" target="_blank">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </div>
                    <div className="footer-widget__social">
                      <a href="#none" target="_blank">
                        <i className="fab fa-linkedin"></i>
                      </a>
                      <a href="#none" target="_blank">
                        <img
                          src={tiktok}
                          className="custom-icon"
                          alt="tik-tok"
                        />
                      </a>
                      <a href="/kapcsolat" target="_blank">
                        <i className="fa fa-envelope"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-8">
                <div className="footer-widget__links-wrap">
                  <div className="footer-widget">
                    <h3 className="footer-widget__title mb-4">Töltsd le itt</h3>
                    <div className="d-flex flex-column">
                      <a href="#none" className="btn-img mb-3">
                        <img
                          src={AppleDownload}
                          alt="Download on the Apple Store"
                        />
                      </a>
                      <a href="#none" className="btn-img mb-3">
                        <img src={GoogleDownload} alt="Get it on Google Play" />
                      </a>
                      <a href="#none" className="btn btn-dark">
                        <i className="fa fa-desktop"></i>
                        <span className="ms-1 font-weight-bold">Web App</span>
                      </a>
                    </div>
                  </div>

                  <div className="footer-widget">
                    <h3 className="footer-widget__title mb-4">
                      Kérdések, kapcsolat
                    </h3>
                    <ul className="list-unstyled footer-widget__links-list">
                      <li>
                        <a href="/kapcsolat">Kapcsolat</a>
                      </li>
                      <li>
                        <a href="/gyik">GY.I.K.</a>
                      </li>
                      <li>
                        <a href="/egyuttmukodes">Együttműködés</a>
                      </li>
                      <li>
                        <a href="/media-kit">MediaKIT</a>
                      </li>
                    </ul>
                  </div>

                  <div className="footer-widget">
                    <h3 className="footer-widget__title mb-4">Információk</h3>
                    <ul className="list-unstyled footer-widget__links-list">
                      <li>
                        <a href="/impresszum">Impresszum</a>
                      </li>
                      <li>
                        <a href="/aszf">ÁSZF</a>
                      </li>
                      <li>
                        <a href="/adatvedelmi-nyilatkozat">
                          Adatvédelmi nyilatkozat
                        </a>
                      </li>
                      <li>
                        <a href="/biztonsag">Biztonság</a>
                      </li>
                      <li>
                        <a href="/cookie-tajekoztato">Cookie tájékoztató</a>
                      </li>
                      <li>
                        <a href="/jogi-nyilatkozat">Jogi nyilatkozat</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer__bottom">
          <div className="container text-center">
            <div className="row">
              <div className="col-md-4">
                <div className="position-relative">
                  <a
                    href="https://www.hiventures.hu"
                    className="hive-logo"
                    target="_blank"
                  >
                    <img src={hiventuresLogo} alt="Hiventures" />
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <p>
                  © Copyright 2021 - 2023 Minden jog fenntartva!
                  <br />
                  My Food Protocol AI Analytics Kft.
                </p>
              </div>
            </div>
          </div>
        </div>
        <a href="./szechenyi2020.html" className="eu-logo" target="_blank">
          <img src={EUblokk} alt="SZÉCHENYI 2020" />
        </a>
      </footer>
    </div>
  );
};

export default Footer;
